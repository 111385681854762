
import React from "react"
import { Container } from "reactstrap"

import "../styles/components/hero.scss"

import companyThumbnail from "../images/fahr_erlebnis_weiss_transparent.png"


const HeroSeminar = ({
    title="SEMINARRÄUME",
    image,
    fullScreen=false
}) => {
  return (
    <div
      className="heroGastro"
      id="hero"
      style={{
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        background: `linear-gradient( rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.11) ), 
        url(${image?.src})`, height: fullScreen ? '100vh' : '70vh' 
      }}
    >
      <section className="h-100 d-flex align-items-center text-center bg-dark-100">
        <Container>
        <img
            src={companyThumbnail}
            alt="fahr erlebnis logo"
            className="img-head-homeGastro"
        />  
          <main className="hero-contentGastro">
          <h1 className="titleGastro">{title}</h1>
            
          </main>
        </Container>
      </section>
    </div>
  )
}

export default HeroSeminar

