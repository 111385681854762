import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col, Button } from "reactstrap"

import Layout2 from "../components/layout2"
import SEO from "../components/seo"
import Member from "../components/SeminarRaum/member"
import Footer from "../components/footer"
import HeroSeminar from "../components/hero-seminar"

import "../styles/pages/das-team.scss"

const SeminarPage = ({ data: { allContentfulSeminarRaume, header } }) => {
  return (
    <Layout2>
      <SEO title="Home" />
      <div className="rinde">
        <HeroSeminar image={header.childImageSharp.fluid} />

        <Container>
          <Row>
            <Col>
              <p className="subPage-text">
                Wir bieten kreative und moderne Räume auf dem idyllischen
                Klosterareal. Repräsentative Raumtypen in verschiedenen Grössen
                stehen für variable Gruppen bereit zur Miete. Ob Sie die Räume
                für einen halben Tag, einen ganzen Tag oder gleich eine Woche
                buchen, ist Ihnen überlassen. Eines ist immer gleich: Wir bieten
                Raum für Austausch. Hier werden Ideen entwickelt, finden
                Arbeitsprozesse statt, werden Pläne gemacht und Entscheide
                gefällt.
                <br></br> <br/>
                Gerne geben wir persönlich Auskunft zu den Preisen. 
                Möglich sind einzelne Raummieten oder Pauschalen pro Person inklusiv Verköstigung. 
              </p>
              <br></br> 
              <h2 style={{textAlign:'left'}}>Unsere Seminarräume</h2>
            </Col>
          </Row>
        
        </Container>

        <Container className="teamCont">
          {allContentfulSeminarRaume?.edges?.map(member => {
            return <Member {...member?.node} />
          })}
        </Container>

        <Container style={{marginTop:'0px'}}>
          <Row>
            <Col>
              <p className="subPage-text"   style={{textAlign:'left'}}>
              Treten Sie mit uns in Verbindung, wir beraten Sie gerne
              <br/> <br/> 
                <Link to="/kontakt">
                  {" "}
                  <Button style={{ backgroundColor: "#3d804cc0" }}>
                    Kontakt
                  </Button>{" "}
                </Link>
              </p>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    </Layout2>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "seminar.jpg" }) {
      ...fluidImage
    }
    allContentfulSeminarRaume {
      edges {
        node {
          id
          portraitBild {
            id
            file {
              url
              fileName
            }
          }
          desc {
            raw
          }
          bulletPoints {
            raw
          }
          name
        }
      }
    }
  }
`

export default SeminarPage
