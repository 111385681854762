import React from "react"
import { Row, Col } from 'reactstrap'
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { parseOptions } from '../../utils/praseOptions';

import '../../styles/pages/das-team.scss';

const Member = (props) => {
  return (
    <Row className="member">
      {props?.portraitBild?.file?.url && (
        <Col lg="6" md="5">
          <img src={props.portraitBild.file.url} alt={props?.portraitBild?.file?.fileName} />
        </Col>
      )}
      <Col lg="6" md="7">
        {props?.name && (
          <h3>{props.name}</h3>
        )}
        
        <div className="data-row">
          {/* <span>Funktion:</span> */}
          <p>{renderRichText(props.desc, parseOptions)}</p>
        </div>
        <br></br>
        <div className="data-row">
          
          <p>{renderRichText(props.bulletPoints, parseOptions)}</p>
        </div>
      </Col>
    </Row>
  )
}

export default Member
